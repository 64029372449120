<template>
  <div>
    <el-radio-group v-model="radio">
      <el-radio
        v-for="(value, key, i) in options"
        :key="i"
        :label="key"
        @change="change"
      >
        {{ key }}：{{ value }}
      </el-radio>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  name: "single",
  props: {
    options: {
      type: Object,
    },
    index: {
      type: Number,
    },
    clean: {
      type: Boolean,
    },
  },
  data: () => ({
    radio: "",
    objOptions: {},
  }),
  watch: {
    clean: function (val, oldVal) {
      if (val) {
        this.radio = "";
      }
    },
  },
  created() {
    // this.objOptions = JSON.parse(this.options);
    // console.log(this.objOptions);
  },
  methods: {
    change(val) {
      this.$emit("changeSelect", { val, index: this.index });
    },
    // cleanSelect() {
    //   this.radio = "";
    // },
  },
};
</script>

<style>
</style>