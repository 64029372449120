<template>
  <div class="content">
    <div class="float-menu">
      <div class="menu-top">课本练习汇总</div>
      <el-divider></el-divider>
      <div class="menu-btn">
        <button @click="drawer = true">操作</button>
        <!-- <button @click="submitTest">提交</button> -->
      </div>
    </div>
    <div class="select">
      <el-tree
        :data="data"
        :props="defaultProps"
        @node-click="handleNodeClick"
      ></el-tree>
    </div>
    <div class="testpaper">
      <el-card class="box-card" v-for="(questions, i) in questionList" :key="i">
        <div slot="header" class="clearfix">
          <span> {{ i + 1 }}、 {{ questions.question }} </span>
          <!-- <el-button style="float: right; padding: 3px 10px" type="text"
            >操作按钮</el-button
          > -->
          <span v-if="showAnalysis" style="float: right; padding: 0px 10px">
            <i
              v-show="questions.answer == questions.select"
              class="el-icon-check"
            ></i>
            <i
              v-show="questions.answer != questions.select"
              class="el-icon-close"
            ></i>
            <!-- {{ questions.answer == questions.select ? "√" : "×" }} -->
          </span>
          <el-rate
            class="el-rate"
            v-model="questions.difficulty"
            disabled
            show-score
            text-color="#ff9900"
            score-template="{难度系数}"
            style="float: right; padding: 1px 0"
          >
          </el-rate>
        </div>
        <single
          :options="questions.options"
          :index="i"
          :clean="clean"
          @changeSelect="changeSelect($event)"
        ></single>
        <el-collapse
          v-if="showAnalysis"
          @change="handleChange"
          class="el-collapse"
        >
          <el-collapse-item title="查看改问题解析" name="1">
            <div class="answer">正确答案：{{ questions.answer }}</div>
            <div class="analysis">
              {{ questions.analysis }}
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-card>
    </div>
    <el-drawer
      :title="treeLabel"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      size="20%"
    >
      <div class="boxList">
        <span
          v-for="(item, i) in questionList"
          :key="i"
          :class="{
            'question-no': true,
            'is-focus': focuNo == i,
            'is-done': item.select,
          }"
        >
          {{ i + 1 }}
        </span>
      </div>
      <div class="submit-btn">
        <el-button type="primary" round @click="submitTest">提交答案</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import single from "./components/single.vue";
export default {
  components: { single },
  data() {
    return {
      questionList: [],
      showAnalysis: false,
      clean: false,
      data: [
        {
          label: "课本练习汇总",
          children: [
            {
              label: "综合英语-1",
              // children: [{ label: "综合英语-2" }],
            },
            {
              label: "综合英语-2",
              // children: [{ label: "综合英语-2" }],
            },
          ],
        },
      ],
      treeLabel: "英语题目练习",
      direction: "rtl",
      drawer: false,
      defaultProps: {
        children: "children",
        label: "label",
      },
      focuNo: 0,
    };
  },
  mounted() {
    const res = this.getQuestionsByTips({});
  },
  methods: {
    handleNodeClick(data) {
      if (!data.children) {
        const params = { belong: data.label };
        this.getQuestionsByTips(params);
        this.treeLabel = data.label;
      }
    },
    submitTest() {
      this.$confirm("确定提交本次练习吗？提交后可看到问题答案与解析", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "提交成功!",
          });
          // this.handleClose();
          this.drawer = false;
          this.showAnalysis = true;
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消提交",
          });
        });
    },
    handleClose(done) {
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
      done();
    },

    async getQuestionsByTips(params) {
      const res = await this.$store.dispatch("english/getAllQuestions", params);

      this.questionList = res.results.res;
      for (let i = 0; i < this.questionList.length; i++) {
        this.questionList[i].options = JSON.parse(this.questionList[i].options);
        this.questionList[i].select = "";
      }

      this.clean = true;

      this.showAnalysis = false;
      return res;
    },
    handleChange(val) {
      // console.log(val);
    },
    changeSelect(data) {
      if (data.index !== undefined && data.val) {
        this.questionList[data.index].select = data.val;
        this.clean = false;
        this.focuNo = data.index;
      }
      // console.log(this.questionList[data.index].select);
    },
  },
};
</script>

<style scoped>
@import "../../assets/CSS/common.css";
.content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 90vw;
  margin-left: 5vw;
  padding-top: 20px;
}
.select {
  width: 300px;
}
.testpaper {
  width: 1370px;
}
.box-card {
  margin-bottom: 20px;
}
.el-rate {
  display: inline-block;
}
.el-collapse {
  margin-top: 20px;
}
.answer {
  color: rgb(255, 153, 0);
  font-size: 16px;
  padding-left: 30px;
}
.analysis {
  color: rgb(105, 105, 105);
  padding-left: 30px;
}
.el-collapse >>> .el-collapse-item__header {
  color: #409eff;
}
.title {
  position: fixed;
  top: 60px;
  /* left: 50%; */
  width: calc(90vw - 300px);
  font-size: 30px;
  font-weight: 700;
  color: #409eff;
  text-align: center;
  letter-spacing: 3px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
}
.float-menu {
  position: fixed;
  right: 0px;
  top: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
  text-align: center;
  padding: 20px 5px;
  border-radius: 20px 0 0 20px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.08);
  user-select: none;
}
.float-menu .menu-top {
  font-size: 30px;
}
.float-menu .menu-btn {
  width: 46px;
  height: 80px;
}
.float-menu .menu-btn button {
  font-size: 24px;
  height: 72px;
  background-color: skyblue;
  border-radius: 15px;
  cursor: pointer;
  transition: all 1s;
}
.float-menu .menu-btn button:hover {
  background-color: tomato;
  color: #fff;
}
.boxList {
  display: flex;
  padding: 0 20px;
  user-select: none;
}
.question-no {
  position: relative;
  display: inline-block;
  margin: 10px 10px;
  width: 50px;
  height: 50px;
  border: 1px solid skyblue;
  line-height: 50px;
  text-align: center;
}
.is-done {
  background-color: #c0e3ff85;
}
.is-focus::after {
  position: absolute;
  right: 3px;
  top: 2px;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(255, 153, 0);
}
.submit-btn {
  margin-top: 20px;
  text-align: center;
}
</style>